import * as React from "react";
import { Link } from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next"

import * as styles from "./footer.module.css";

import facebook from "../images/icon.png";
import instagram from "../images/icon.png";
import youtube from "../images/icon.png";

const Footer = () =>  {

    const {t} = useTranslation();

    return (
        <div class={styles.footer}>
        <footer>
            <div class={styles.container}>
                <div class="row justify-content-center">
                    <div class="col-sm-4 col-md-3 item">
                        <h3>{t("About")}</h3>
                        <ul>
                            <li><Link to="/blog">{t("Blog")}</Link></li>
                            <li><a href="https://www.fono.com.tr">{t("About")}</a></li>
                            <li><a href="/help">{t("Help")}</a></li>
                            <li><a href="/privacy">{t("Privacy")}</a></li>
                        </ul>
                    </div>
                    <div class="col-sm-4 col-md-3 item">
                        <h3>{t("Learn")}</h3>
                        <ul>
                        <li><Link to="/learnenglish">{t("LearnEnglish")}</Link></li>
                        <li><Link to="/learnturkish">{t("LearnTurkish")}</Link></li>
                        </ul>
                    </div>
                    <div class="col-sm-4 col-md-3 item">
                        <h3>{t("Apps")}</h3>
                        <ul>
                            <li><a href="#">{t("DopaIOS")}</a></li>
                            <li><a href="#">{t("DopaAndroid")}</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-3 item social"><a href="#"><i class="icon ion-social-facebook"></i></a><a href="#"><i class="icon ion-social-twitter"></i></a><a href="#"><i class="icon ion-social-snapchat"></i></a><a href="#"><i class="icon ion-social-instagram"></i></a>
                        <p class="copyright">Dopa © 2022</p>
                    </div>
                </div>
            </div>
        </footer>
        </div>
    );
};

export default Footer;
