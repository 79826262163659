import * as React from "react";
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import * as styles from './navbar.module.css'
import {Link, useI18next} from 'gatsby-plugin-react-i18next'
import { StaticImage } from "gatsby-plugin-image"
import {useTranslation} from "gatsby-plugin-react-i18next"

export default function NavBar() {
    const {languages, originalPath} = useI18next();
    const {t} = useTranslation();

    function getLanguageName(code) {
        let name = '';
        switch (code) {
            case 'en': name = 'English';
            break;
            case 'tr': name = 'Türkçe';
            break;
            default: name = 'English';
            break;
        }
        return name;
    }

    return (
        <Navbar className={styles.colorednav} sticky="top" expand="lg">
        <Container>
            <Navbar.Brand href="/">
                <StaticImage src="../images/dopalogo.svg" width={140} height={40} alt="Logo Image" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
            <Nav>
                <a className="nav-link" href="https://app.dopawords.com">{t("Login")}</a>
                <NavDropdown title={t("SiteLanguage")} id="basic-nav-dropdown">
                <ul className={styles.languages}>
                {languages.map((lng) => (
                    <li key={lng}>
                    <Link to={originalPath} language={lng}>
                        {getLanguageName(lng)}
                    </Link>
                    </li>
                ))}
                </ul>
                </NavDropdown>
            </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
    )
  }